























































































































import { Component, Vue } from "vue-property-decorator";
import { AxiosError, AxiosResponse } from "axios";
import "simple-syntax-highlighter/dist/sshpre.css";
import CloudEvent, { SerializedCloudEvent } from "@/models/events";
import EventAPi from "@/services/api/event";
import { DatatableFooterProps, DefaultFooterProps } from "@/types/veutify";
import SearchInput from "@/components/SearchInput.vue";
import { ApiResponse } from "@/services/api/axios";
// eslint-disable-next-line @typescript-eslint/no-var-requires
const SshPre = require("simple-syntax-highlighter");
@Component({
    components: {
        SearchInput,
        SshPre,
    },
})
export default class AdminEventsIndex extends Vue {
    loadingEvents: boolean = true;
    events: Array<CloudEvent> = [];
    query: string = "";
    footerProps: DatatableFooterProps = DefaultFooterProps;
    headers = [
        {
            text: "Event ID",
            value: "id",
        },
        {
            text: "Occurred At",
            value: "timeTimestamp",
        },
        {
            text: "Source",
            value: "source",
        },
        {
            text: "Type",
            value: "type",
        },
        {
            text: "Content Type",
            value: "dataContentType",
        },
        {
            text: "Spec Version",
            value: "specVersion",
        },
        { text: "Details", value: "data-table-expand" },
    ];

    mounted() {
        this.loadCloudEvents();
    }

    onQueryChanged(query: string) {
        this.query = query;
    }
    loadCloudEvents() {
        EventAPi.adminIndex()
            .then((response: AxiosResponse) => {
                this.events = response.data.data.map(
                    (event: SerializedCloudEvent) => {
                        return new CloudEvent(event);
                    }
                );
                this.loadingEvents = false;
            })
            .catch(this.handleAxiosError);
    }

    emitErrorNotification(error: Error) {
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.message
        );
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.emitErrorNotification(
            new Error(error?.response?.data?.message ?? error.message)
        );
    }
}
