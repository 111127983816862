import { AxiosResponse } from "axios";
import Axios from "./axios";

class Event {
    private axios: typeof Axios;

    constructor(axios: typeof Axios) {
        this.axios = axios;
    }

    adminIndex(): Promise<AxiosResponse> {
        return this.axios.get("/admin/events");
    }
}

export default new Event(Axios);
